<template>
  <div class="h-full w-full">
    <FixedHeader :flat="flat_header">
      <div class="flex flex-row justify-between items-center head" >
        <button>
          <BackButton outlined dark @click="$router.replace($route.meta.back_ref)"/>
        </button>
        <span class="text-2xl font-bold"><T>public-offer</T></span>
      </div>
    </FixedHeader>
    <Loader v-if="loading" :value="true" class="mx-auto"/>
    <div class="p-1" v-else>
      <pre class="text-xs whitespace-pre-line">
        {{ offer_text }}
      </pre>
    </div>
  </div>
</template>

<script>
import FixedHeader from '@/views/tools/FixedHeader.vue'
import BackButton from '@/icons/BackIcon.vue'
import Loader from '@/icons/ContentLoading.vue'
import flat_header from '../mixins/flat_header'

export default {
  mixins:[flat_header],
  data: () => ({
    loading: true,
    offer_text: undefined
  }),
  computed:{
    offer_link(){
      return `${this.$store.getters.url}/proxypass/${this.$store.getters.appuid}/media/offer`
    }
  },
  async mounted(){
    this.loading = true
    let r = await fetch(this.offer_link)
    if (r.status != 200) {
      this.offer_text = this.$t('offer-unreachable')
    } else {
      this.offer_text = await r.text()
    }
    this.loading = false
  },
  components:{
    FixedHeader,
    BackButton,
    Loader
  }
}
</script>